<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Two bottles of
        <chemical-latex content="HCl" />
        were located in the analytical chemistry lab. The molarity of the two solutions could not be
        read with clarity. On the basis of
        <stemble-latex content="$10$" />
        titrations the average molarity of the solution from the first bottle was found to be
        <number-value :value="conc" unit="\text{M}" />
        . On the basis of
        <stemble-latex content="$6$" />
        titrations the average molarity of the solution from the second bottle was found to be
        <number-value :value="conc2" unit="\text{M}" />
        . The
        <stemble-latex content="$16$" />
        data yielded a pooled value of
        <stemble-latex content="$\text{s}_\text{p}$" />
        of
        <number-value :value="sp" unit="\text{M}" />
        . Calculate the following before determining whether there is a significant difference
        between the two bottles of
        <chemical-latex content="HCl:" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Standard Error of the Difference in Means:}$"
        class="mb-3"
      />

      <calculation-input v-model="inputs.input2" prepend-text="$\text{T-test:}$" class="mb-3" />

      <calculation-input
        v-model="inputs.input3"
        prepend-text="$\text{Degrees of Freedom:}$"
        class="mb-3"
      />

      <p class="mb-2">
        At the
        <stemble-latex content="$95\%$" />
        confidence level, is there a significant difference in concentration between the two bottles
        of
        <chemical-latex content="HCl" />
        ?
      </p>
      <v-radio-group v-model="inputs.input4" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUPEI2210A2Q4',
  components: {
    StembleLatex,
    ChemicalLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {text: 'Yes', value: 'yes'},
        {text: 'No', value: 'no'},
      ],
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
    conc2() {
      return this.taskState.getValueBySymbol('conc2').content;
    },
    sp() {
      return this.taskState.getValueBySymbol('sp').content;
    },
  },
};
</script>
